.floating-label-input {
    position: relative;
    margin-bottom: 20px;
  }
  
  .floating-label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    transition: all 0.2s ease;
    pointer-events: none;
    opacity: 0.5;
  }
  
  .floating-label-input input {
    width: 90%;
    padding: 10px;
    border: 1px solid rgb(61, 61, 61);
    background-color: rgb(61, 61, 61);
    border-radius: 4px;
    font-size: 16px;
  }
  
  .floating-label-input.focused .floating-label,
  .floating-label-input input:not(:placeholder-shown) + .floating-label {
    top: -25px;
    font-size: 12px;
    opacity: 1;
  }
  